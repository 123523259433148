import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import PageLayout from "../components/layout/page/page"

const ContestRulesPage = () => (
  <Layout>
    <SEO title="Contest Rules" keywords="contest rules, contest, rules" />
    <PageLayout name="Contest Rules" />
    <section style={{ marginBottom: "70px" }}>
      <div className="container">
        <div className="row" style={{ padding: "0 15px" }}>
          <div className="privacy-content">
            <div className="">
              {/* <h1 className="sans">Our Privacy Policy</h1> */}
              {/* <div className="">
                <p className="sans p-update">
                  Last updated: {data.site.siteMetadata.date}
                </p>
              </div> */}
            </div>
            <p className="sans p-details">
              NO PURCHASE IS NECESSARY TO ENTER OR WIN. A PURCHASE WILL NOT
              INCREASE YOUR CHANCES OF WINNING. ALL FEDERAL, STATE, LOCAL, AND
              MUNICIPAL LAWS AND REGULATIONS APPLY. VOID WHERE PROHIBITED.
            </p>
            <div className="p-content">
              <div className="d-flex justify-content-left">
                <h3 className="sans"> 1. Eligibility </h3>
              </div>
              <p>
                Meal Giveaway (the "Contest") is open to legal residents of the
                fifty (50) United States and the District of Columbia who are at
                least eighteen (18) years old at the time of entry. Employees of
                Magi Media Group and other companies associated with the
                promotion of the Contest, and their respective parents,
                subsidiaries, affiliates and advertising and promotion agencies
                as well as the immediate family (spouse, parents, siblings, and
                children) and household members of each such employee are not
                eligible. The Contest is subject to federal, state, and local
                laws and regulations.
              </p>
              <div className="d-flex justify-content-left">
                <h3 className="sans"> 2. Sponsor </h3>
              </div>
              <p>
                  The Contest is sponsored by Magi Media Group, located at 201 E
                Center St Ste 112, Anaheim, California 92805.
              </p>
              <div className="d-flex justify-content-left">
                <h3 className="sans"> 3. Agreement to Official Rules</h3>
              </div>
              <p>
                Participation in the Contest constitutes entrant's full and
                unconditional agreement to and acceptance of these Official
                Rules and the decisions of the Sponsor, which are final and
                binding. Winning a prize is contingent upon being compliant with
                these Official Rules and fulfilling all other requirements set
                forth herein.{" "}
              </p>
              <div className="d-flex justify-content-left">
                <h3 className="sans"> 4. Contest Period </h3>
              </div>
              <p>
                The Contest begins on May 29, 2022 at 5:50 pm Pacific Time Zone
                and ends on June 13, 2022 at 5:00 pm Pacific Time Zone (the
                "Contest Period"). Entries that are submitted before or after
                the Contest Period will be disqualified. Submissions will be
                accepted for the duration of the Contest using any of the
                following methods:   Online{" "}
              </p>
              <div className="d-flex justify-content-left">
                <h3 className="sans"> 5. How to Enter </h3>
              </div>
              <p>
                Online: This method of entry will be available by visiting our
                website (https://cash.besteatslosangeles.com/lp/129497/lp129497)
                and following the directions provided to fill out the entry
                information, and submit.
                <br /> <br /> Limit one (1) entry per person, per email address,
                and per household for the duration of the Contest Period,
                regardless of method of entry. Entries received from any person,
                e-mail address, or household in excess of the stated limitation
                will be void. All entries become the property of Sponsor and
                will not be acknowledged or returned.
              </p>
              <div className="d-flex justify-content-left">
                <h3 className="sans"> 6. Prize Drawing </h3>
              </div>
              <p>
                On or about June 13, 2022, the Sponsor will select potential
                winners in a random drawing from among all eligible entries
                received. The odds of being selected depend on the number of
                entries received. The Sponsor will attempt to notify the
                potential winner via telephone or email on or about June 14,
                2022. If the potential winner cannot be contacted within five
                (5) days after the date of the first attempt to contact him/her,
                the Sponsor may select an alternate potential winner in his/her
                place at random from the remaining non-winning, eligible
                entries.
              </p>
              <div className="d-flex justify-content-left">
                <h3 className="sans"> 7. Winner Notification </h3>
              </div>
              <p>
                The potential winners will be notified by email, mail or phone.
                Each potential Grand and First Prize winner (parent/legal
                guardian if a minor in his/her state of residence) will be
                required to complete, electronically sign and submit a
                Declaration of Compliance within five (5) days of the date
                notice or attempted notice is sent, in order to claim his/her
                prize. If a potential winner cannot be contacted, or fails to
                submit the Declaration of Compliance within the required time
                period (if applicable), or prize is returned as undeliverable,
                potential winner forfeits prize. If the potential winner is at
                least 18 but still considered a minor in his/her jurisdiction of
                residence, Sponsor reserves the right to award the prize in the
                name of his/her parent or legal guardian, who will be required
                to sign the Declaration of Compliance on the potential winners
                behalf and fulfill any other requirements imposed on winner set
                forth herein. Potential winners must continue to comply with all
                terms and conditions of these Official Rules, and winning is
                contingent upon fulfilling all requirements. In the event that a
                potential winner is disqualified for any reason, Sponsor will
                award the applicable prize to an alternate winner by random
                drawing from among all remaining eligible entries. Only three
                (3) alternate drawings will be held, after which the prize will
                remain un-awarded. Prizes will be fulfilled approximately 8-10
                weeks after the conclusion of the Contest.
              </p>
              <div className="d-flex justify-content-left">
                <h3 className="sans"> 8. Prizes </h3>
              </div>
              <p>
                Grand Prize (8) $30 meals at Neighbarista in Santa Clarita
                Valley, CA.
              </p>
              <div className="d-flex justify-content-left">
                <h3 className="sans">9. General Conditions </h3>
              </div>
              <p>
                In the event that the operation, security, or administration of
                the Contest is impaired in any way for any reason, including,
                but not limited to fraud, virus, bug, worm, unauthorized human
                intervention or other technical problem, or in the event the
                Contest is unable to run as planned for any other reason, as
                determined by Sponsor in its sole discretion, the Sponsor may,
                in its sole discretion, either (a) suspend the Contest to
                address the impairment and then resume the Contest in a manner
                that best conforms to the spirit of these Official Rules or (b)
                terminate the Contest and, in the event of termination, award
                the prize at random from among the eligible, non-suspect entries
                received up to the time of the impairment. The Sponsor reserves
                the right in its sole discretion to disqualify any individual it
                finds to be tampering with the entry process or the operation of
                the Contest or to be acting in violation of these Official Rules
                or in an unsportsmanlike or disruptive manner. Any attempt by
                any person to damage the website or undermine the legitimate
                operation of the Contest may be a violation of criminal and
                civil law, and, should such an attempt be made, the Sponsor
                reserves the right to seek damages (including attorney's fees)
                and any other remedies from any such person to the fullest
                extent permitted by law. Failure by the Sponsor to enforce any
                provision of these Official Rules shall not constitute a waiver
                of that provision.
              </p>
              <div className="d-flex justify-content-left">
                <h3 className="sans">
                   10. Release and Limitations of Liability{" "}
                </h3>
              </div>
              <p>
                By participating in the Contest, entrants agree to release and
                hold harmless the Sponsor, and each of their respective parents,
                subsidiaries, affiliates, advertising and promotion agencies,
                other companies associated with the Contest, and each of their
                respective officers, directors, employees, shareholders,
                representatives, and agents (the Released Parties) from and
                against any claim or cause of action arising out of
                participation in the Contest or receipt or use of the prize
                (including any travel or activity related thereto), including,
                but not limited to: (a) any technical errors associated with the
                Contest, including lost, interrupted or unavailable Internet
                Service Provider (ISP), network, server, wireless service
                provider, or other connections, availability or accessibility or
                miscommunications or failed computer, satellite, telephone,
                cellular tower or cable transmissions, lines, or technical
                failure or jumbled, scrambled, delayed, or misdirected
                transmissions or computer hardware or software malfunctions,
                failures or difficulties; (b) unauthorized human intervention in
                the Contest; (c) mechanical, network, electronic, computer,
                human, printing or typographical errors; (d) application
                downloads, (e) any other errors or problems in connection with
                the Contest, including, without limitation, errors that may
                occur in the administration of the Contest, the announcement of
                the winner, the cancellation or postponement of the event and/or
                the flyover, if applicable, the incorrect downloading of the
                application, the processing of entries application downloads or
                in any Contest-related materials; or (f) injury, death, losses
                or damages of any kind, to persons or property which may be
                caused, directly or indirectly, in whole or in part, from
                entrants participation in the Contest or acceptance, receipt or
                misuse of the prize (including any travel or activity related
                thereto). Entrant further agrees that in any cause of action,
                the Released Parties liability will be limited to the cost of
                entering and participating in the Contest, and in no event shall
                the entrant be entitled to receive attorney's fees. Released
                Parties are also not responsible for any incorrect or inaccurate
                information, whether caused by site users, tampering, hacking,
                or by any equipment or programming associated with or utilized
                in the Contest. Entrant waives the right to claim any damages
                whatsoever, including, but not limited to, punitive,
                consequential, direct, or indirect damages.
              </p>
              <div className="d-flex justify-content-left">
                <h3 className="sans"> 11. Disputes </h3>
              </div>
              <p>
                Except where prohibited, each entrant agrees that any and all
                disputes, claims and causes of action arising out of, or
                connected with, the Contest or any prize awarded shall be
                resolved individually, without resort to any form of class
                action, and exclusively by the appropriate court located in
                California. All issues and questions concerning the
                construction, validity, interpretation and enforceability of
                these Official Rules, entrants rights and obligations, or the
                rights and obligations of the Sponsor in connection with the
                Contest, shall be governed by, and construed in accordance with,
                the laws of California, without giving effect to any choice of
                law or conflict of law rules, which would cause the application
                of the laws of any jurisdiction other than California.
              </p>
              <div className="d-flex justify-content-left">
                <h3 className="sans"> 12. Privacy </h3>
              </div>
              <p>
                Information collected from entrants is subject to sponsor's
                privacy policy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default ContestRulesPage
